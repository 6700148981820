// IIFE - Immediately Invoked Function Expression
(function($, window, document) {

    // The $ is now locally scoped 

    // Listen for the jQuery ready event on the document
    $(function() {

        // The DOM is ready!

    });

    AOS.init();

    function iNeedAHero() {
      var options = [
        "contract big companies",
        "hire a dev for my company",
        "Dev.web"
      ];
      var selectedOption = prompt("Who can help me: " + options.join(" | "));

      if (selectedOption === "Dev.web") {
        alert("Problem Solved...");
        return true;
      }
      alert("My problem is not solved...");
      return false;
    }

    $('.trigger-code').click(function(e){
      e.preventDefault();
      iNeedAHero();
    });

    //Lighter trigger
    var lightsTrigger = document.getElementsByClassName("lights-trigger");
    
    var toggleLights = function() {
        document.documentElement.classList.toggle("dark");
    };

    for (var i = 0; i < lightsTrigger.length; i++) {
        lightsTrigger[i].addEventListener('click', toggleLights, false);
    }

    /*text writer*/
    var TxtType = function(el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 2000;
        this.txt = '';
        this.tick();
        this.isDeleting = false;
    };

    TxtType.prototype.tick = function() {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];

        if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

        var that = this;
        /*var delta = 200 - Math.random() * 100;*/
        var delta = 150 - Math.random() * 100;

        /*if (this.isDeleting) { delta /= 2; }*/
        if (this.isDeleting) { delta /= 4; }

        if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
        }

        setTimeout(function() {
        that.tick();
        }, delta);
    };

    window.onload = function() {
        var elements = document.getElementsByClassName('typewrite');
        for (var i=0; i<elements.length; i++) {
            var toRotate = elements[i].getAttribute('data-type');
            var period = elements[i].getAttribute('data-period');
            if (toRotate) {
              new TxtType(elements[i], JSON.parse(toRotate), period);
            }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
        document.body.appendChild(css);
    };


    /*Anchor scroll*/
    const anchors = document.querySelectorAll(".scroll-anchor a");
     
    for (const anchor of anchors) {
      anchor.addEventListener("click", clickHandler);
    }
     
    function clickHandler(e) { 
      let nav = document.querySelector("nav").offsetHeight;
      const href = this.getAttribute("href");
      const offsetTop = document.querySelector(href).offsetTop;
      e.preventDefault();
      if (window.innerWidth < 769) {
        nav = 64;
      }
     /*console.log(nav);*/
      scroll({
        top: offsetTop-nav,
        //top: offsetTop,
        behavior: "smooth"
      });
      document.body.classList.remove("menu-active");
    }    

    //Menu trigger
    var menuTrigger = document.getElementsByClassName("menu-trigger-btn");
    
    var toggleMenu = function() {
        document.body.classList.toggle("menu-active");
    };

    for (var i = 0; i < menuTrigger.length; i++) {
        menuTrigger[i].addEventListener('click', toggleMenu, false);
    }


    /*Dynamic nav*/
    document.addEventListener('scroll', function(e) {
      lastKnownScrollPosition = window.scrollY;
      WindowWidth = window.innerWidth;
      //console.log(lastKnownScrollPosition);
      wHeight = window.innerHeight;
      mainHeader = document.getElementById('dynamic-nav');
      Bodyclasses = document.body.getAttribute('class').split(' ');

      if (mainHeader) { 
       /* if (mainHeader.classList.contains('md-header-blog')) {
          var compareHeight = 60;
        }else{
          var compareHeight = wHeight/3;
        }*/
        /*var compareHeight = 60;*/
        var compareHeight = wHeight/2;

        if (lastKnownScrollPosition >= compareHeight ) {
          mainHeader.classList.add('-visible');
        }else{
          mainHeader.classList.remove('-visible');
        }
        
      }
      /*if (WindowWidth < 768 && Bodyclasses.indexOf('-fix-mobile') > -1) {
        e.preventDefault();
        window.scrollTo(0, 0);
      }*/

      /*if (!ticking) {
        window.requestAnimationFrame(function() {
          doSomething(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }*/
    });
    

    /*scroll Spy*/
    const spyScrolling = ( ) => {
      const sections = document.querySelectorAll( '.-section' );

      window.onscroll = ( ) => {
        const scrollPos = document.documentElement.scrollTop || document.body.scrollTop;
        const nav = document.querySelector("nav").offsetHeight;
        const scrollPosNav = scrollPos + nav;

        for ( let s in sections )
          if ( sections.hasOwnProperty( s ) && sections[ s ].offsetTop <= scrollPosNav ) {
            const id = sections[ s ].id;
            //console.log(id);
            document.querySelector( '.-active-spy' ).classList.remove( '-active-spy' );
            document.querySelector( `a[href*=${ id }]` ).parentNode.classList.add( '-active-spy' );
          }
      } 
    }

    spyScrolling( );
    

}(window.jQuery, window, document));